@import 'styles/_scaffold';

.textContainer {
  padding-top: 30px;
  padding-right: divide($grid-gutter-width, 2);
  padding-bottom: 40px;
  padding-left: divide($grid-gutter-width, 2);

  @include media-breakpoint-only(md) {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    padding-bottom: 130px;
  }
}

.mobileMargin {
  @include media-breakpoint-down(sm) {
    margin-right: -divide($grid-gutter-width, 2);
    margin-left: -divide($grid-gutter-width, 2);
  }
}

.sectionHeading {
  margin-bottom: 1em;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }
}

.sectionHeadingBgLight {
  color: $orange;
}

.contentRow {
  padding-top: 30px;

  @include media-breakpoint-only(md) {
    padding-top: 50px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 75px;
  }
}

.contentPadded {
  @include media-breakpoint-only(md) {
    padding-right: 80px;
    padding-left: 80px;
  }

  @include media-breakpoint-up(lg) {
    padding-right: 200px;
    padding-left: 200px;
  }
}

.subheadingText {
  font-family: $font-heading;
  font-size: 30px;
  font-weight: $font-bold;
  line-height: 1;

  @include media-breakpoint-only(md) {
    font-size: 40px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 60px;
  }

  &.subheadingFirst {
    margin-bottom: 1em;
  }

  &.subheadingSecond {
    @include media-breakpoint-up(md) {
      margin-top: 0.15em;
    }
  }
}

.bodyText {
  font-size: 18px;

  @include media-breakpoint-down(md) {
    &.bodyTextFirst {
      margin-bottom: 1.5em;
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 26px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 30px;

    &.bodyTextFirst {
      padding-right: 20px;
    }
  }

  @include media-breakpoint-up(xxl) {
    font-size: 36px;
    line-height: 1.45;

    &.bodyTextFirst {
      padding-right: 30px;
    }
  }
}
