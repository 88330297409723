@import 'styles/_scaffold';

.imageContainer {
  position: relative;
  width: 100%;
  height: 50vw;
}

.imageContainerHero {
  @include media-breakpoint-up(lg) {
    height: 33vw;
  }
}

.eyesonice {
  height: 33vw;
}

.imageTextContainer {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  color: $white;
}

.headingText {
  font-family: $font-heading;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;

  @include media-breakpoint-only(md) {
    font-size: 50px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 60px;
  }

  span {
    display: inline-block;
    padding: 0.3em 0.4em 0.1em;
    background-color: $orange;
    border-radius: 0.4em;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;

    @include media-breakpoint-only(md) {
      padding-right: 25px;
      padding-left: 25px;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 35px;
      padding-left: 35px;
    }
  }
}

.subheadingText {
  padding-top: 25px;
  font-size: 16px;
  line-height: 18px;

  @include media-breakpoint-only(md) {
    padding-right: 25px;
    padding-left: 25px;
    font-size: 24px;
    line-height: 30px;
  }

  @include media-breakpoint-up(lg) {
    padding-right: 35px;
    padding-left: 35px;
    font-size: 28px;
    line-height: 34px;
  }
}

// .imageTextContainerNormal {
//   position: absolute;
//   top: 30px;
//   padding-right: 15px;
//   padding-left: 15px;
//   color: $white;
//   text-align: center;

//   @include media-breakpoint-up(md) {
//     top: 60px;
//     padding-right: 60px;
//     padding-left: 60px;
//   }
// }

// .headingTextNormal {
//   font-size: 16px;
//   line-height: 18px;
//   text-transform: uppercase;

//   @include media-breakpoint-up(md) {
//     font-size: 30px;
//     line-height: 34px;
//   }
// }

// .subheadingTextNormal {
//   padding-top: 10px;
//   font-size: 24px;
//   line-height: 32px;

//   @include media-breakpoint-only(md) {
//     padding-top: 15px;
//     font-size: 38px;
//     line-height: 48px;
//   }

//   @include media-breakpoint-up(lg) {
//     padding-top: 15px;
//     font-size: 50px;
//     line-height: 64px;
//   }
// }

.caption {
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  @include media-breakpoint-only(md) {
    font-size: 16px;
    line-height: 24px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
    line-height: 36px;
  }
}
