@use 'sass:math';

@import 'styles/_scaffold.scss';

// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  max-width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.aligncenter {
  display: block;
  margin: divide($font-size-base, 2) auto;
}

.alignleft,
.alignright {
  margin-bottom: (divide($font-size-base, 2));
}

@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    margin-right: (divide($font-size-base, 2));
    float: left;
  }

  .alignright {
    margin-left: (divide($font-size-base, 2));
    float: right;
  }
}

// Captions
.wp-caption {
  @extend .img-thumbnail !optional;
}

.wp-caption-text {
  @extend .figure-caption !optional;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only !optional;
  @extend .sr-only-focusable !optional;
}
