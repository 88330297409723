@import 'styles/_scaffold';

.imageContainer {
  position: relative;
  width: 100%;
  height: 60vw;
  max-height: 600px;
}

.headingText {
  font-family: $font-heading;
  font-size: 60px;
  font-weight: $font-bold;
  line-height: 0.85;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 55px;

    &.stampContainer {
      .stamp {
        display: inline-block;
        padding-top: calc(0.23em + 0.02em);
        padding-right: 0.23em;
        padding-left: 0.23em;
        border-radius: 0.29em;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        line-height: 0.9;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 70px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 95px;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 120px;
  }
}

.contentPadded {
  @include media-breakpoint-down(sm) {
    padding-top: 60px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.red {
  color: $red;

  @include media-breakpoint-up(md) {
    &.stampContainer {
      .stamp {
        background-color: $red;
        color: $white;
      }
    }
  }
}

.green {
  color: $green;

  @include media-breakpoint-up(md) {
    &.stampContainer {
      .stamp {
        background-color: $green;
        color: $white;
      }
    }
  }
}

.orange {
  color: $orange;

  @include media-breakpoint-up(md) {
    &.stampContainer {
      .stamp {
        background-color: $orange;
        color: $white;
      }
    }
  }
}

.subheadingText {
  padding-top: 0;
  font-family: $font-heading;
  font-size: 28px;
  font-weight: $font-bold;
  line-height: 36px;

  @include media-breakpoint-up(md) {
    padding-top: 10px;
    font-size: 34px;
    line-height: 42px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 40px;
    line-height: 50px;
  }
}

.subheadingPadding {
  @include media-breakpoint-up(md) {
    padding-top: 60px;
  }
}

.bodyText {
  padding-top: 10px;
  padding-bottom: 20px;

  @include media-breakpoint-up(sm) {
    font-size: 1.2em;
  }

  @include media-breakpoint-only(md) {
    font-size: 1.1em;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.5em;
  }
}

.videoContainer {
  // position: relative;
  // padding-top: 55%;

  @include media-breakpoint-only(xs) {
    margin-right: -15px;
    margin-left: -15px;
  }
}

// .youtube {
//   position: absolute;
//   top: 0;
//   left: 0;
// }

.langSelected {
  pointer-events: none;
  text-decoration: underline;
}

.shareBar {
  padding: 10px 5px;
  background-color: $primary;
  color: $white;
  font-family: $font-heading;
  font-size: 1em;
  font-weight: $font-heavy;
  letter-spacing: 2px;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-only(xs) {
    margin-right: -15px;
    margin-left: -15px;
  }

  @include media-breakpoint-up(lg) {
    padding: 20px 30px;
    font-size: 1.25em;
    line-height: 1.25em;
  }
}

.languageBtn {
  padding: 0;
  border: none;
  margin-left: 10px;
  background-color: transparent;
  color: $white;
  font-weight: $font-heavy;
  letter-spacing: 2px;
  outline: none !important;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    margin-left: 25px;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.socialIcon {
  border: none;
  border-bottom: 2px solid transparent;
  margin-right: 15px;
  margin-bottom: -2px;
  margin-left: 25px;
  background-color: transparent;
  outline: none !important;
  transition: all 0.3s ease;

  &.last {
    margin-right: 0;
  }

  svg {
    vertical-align: top;
  }

  &:focus,
  &:hover {
    border-color: $white;
  }
}
