@import 'styles/_scaffold';

.signupBar {
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
    background: $offwhite;
    @include media-breakpoint-up(md){
      display: none;
    }
}

.barInner {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}