@import 'styles/_scaffold';

.arrow {
  width: 0.4em;
  height: 0.4em;
  margin: 0 10px;
}

.breadcrumbNav {
  padding-top: 10px;
  padding-bottom: 10px;
}

.breadcrumbContainer {
  color: $black;
  font-family: $font-heading;
  font-size: 20px;
  font-weight: $font-heavy;

  a {
    color: $green;
    text-decoration: underline;
  }
}

.shareNav {
  color: $green;
  font-family: $font-heading;
  font-size: 20px;
  font-weight: $font-heavy;
}

.spacer {
  padding: 0 5px;
}

.addMargin {
  margin-bottom: 60px;
}

.shareOpen {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  margin-top: 50px;
}

.share-link {
  transition: 0.4s ease all;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}
