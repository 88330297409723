@import 'styles/_scaffold';

.alertBarContent {
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    font-size: 1.2rem;
  }
}
.alertBarLink {
  text-decoration: none;
}
.alertBarText {
  &:not(:last-child) {
    margin-right: 0.4em;
  }
}
.alertBarLinkText {
  text-decoration: underline;
  font-weight: $font-bold;

  &:hover {
    text-decoration: none;
  }
}

.header {
  padding-top: 5px;
  padding-bottom: 20px;
  background: $white;
  color: $secondary;
}

.logo {
  width: 130px;
  margin: 0;
  font-size: 18px;
  text-transform: uppercase;

  * {
    max-width: 100%;
  }

  // @include media-breakpoint-up(sm) {
  //   width: 150px;
  // }

  @include media-breakpoint-up(lg) {
    width: auto;
  }
}

.logoLink {
  display: block;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    transform: scale(1.03);
  }
}

.translations {
  nav {
    display: inline-block;
    font-size: rem-calc(18px);
    font-weight: normal;
    font-weight: bold;
    text-align: end;
    text-transform: uppercase;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

//
// Mobile Nav
//

.mobileNav {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background: rgba($red, 0.99);
  color: $green;
  opacity: 0;
  // transition: all 0.5s ease-in-out;
  // transition: opacity 1.35s ease-in-out;
  transition: opacity 1s ease-in-out;
  visibility: hidden;

  &.isVisible {
    width: 100%;
    height: 100vh;
    opacity: 1;
    visibility: visible;
  }

  .mobileNavInner {
    position: relative;
    padding-top: 60px;
  }

  .close {
    position: absolute;
    z-index: 1001;
    top: 20px;
    right: 20px;
    display: inline-block;
    color: $white;
    font-size: rem-calc(36px);

    &:hover {
      cursor: pointer;
    }
  }

  li {
    margin-bottom: rem-calc(34px);
    font-size: rem-calc(30px);
  }

  a {
    color: $white;
    font-weight: $font-bold;
    text-decoration: none;
  }

  .dropdown {
    margin-top: 16px;
    margin-bottom: 30px;

    li {
      margin-bottom: 20px;
      line-height: 1em;
    }

    .child {
      font-size: rem-calc(22px);
    }
  }
}

//
// Desktop Nav
//
/* stylelint-disable no-descending-specificity */
.nav {
  margin: 0;
  color: $green;
  font-family: $font-heading;
  font-size: rem-calc(24px);
  font-weight: $font-bold;
  line-height: 1;
  text-transform: uppercase;

  @include media-breakpoint-up(xxl) {
    font-size: rem-calc(28px);
  }

  a {
    display: inline-block;
  }

  a:not(.btn) {
    border-bottom: 3px solid transparent;
    margin-right: 5px;
    margin-left: 5px;
    color: $green;
    text-decoration: none;
    transition: 0.4s ease all;

    @include media-breakpoint-up(xl) {
      margin-right: 15px;
      margin-left: 15px;
    }

    &:hover,
    &:focus {
      border-color: $red;
      color: darken($green, 10%);
    }
  }

  .hasDropdown {
    position: relative;

    .arrow {
      padding-left: 5px;
    }

    &:hover {
      a.parent {
        border-color: $red;
        color: darken($green, 10%);
      }

      .dropdown {
        display: block;
      }
    }
  }

  .dropdown {
    position: absolute;
    z-index: 1000;
    top: 100%;
    display: none;
    min-width: 300px;
    padding: 20px 0 5px 0;
    background: rgba($white, 0.98);
    text-align: left;

    .child {
      display: block;
      padding: 5px 0;
      border: none;
      font-size: rem-calc(26px);
    }
  }
}

.hamburger {
  color: $red;
  font-size: rem-calc(32px);

  a {
    display: inline-block;

    &:hover {
      cursor: pointer;
    }
  }
}

/* stylelint-enable no-descending-specificity */

// @import 'styles/_scaffold';

// .header {
//   padding-top: 20px;
//   padding-bottom: 20px;
//   background: $white;
//   color: $secondary;
// }

// .hide {
//   display: none;
// }

// .logo {
//   width: 130px;
//   margin: 0;
//   font-size: 18px;
//   text-transform: uppercase;

//   * {
//     max-width: 100%;
//   }

//   @include media-breakpoint-up(sm) {
//     width: 150px;
//   }

//   @include media-breakpoint-up(lg) {
//     width: 200px;
//   }
// }

// .logoLink {
//   display: block;
//   transition: all 0.3s ease;

//   &:hover,
//   &:focus {
//     transform: scale(1.03);
//   }
// }

// .alignCenter {
//   align-items: center;
// }

// .headerToggler {
//   padding: 0;
//   margin-left: auto;
//   color: rgba($red, 0.4) !important;
// }

// .headerHamburger {
//   color: $red;
//   font-size: 36px;
// }

// .headerClose {
//   color: $red;
//   font-size: 36px;
// }

// .headerDropdown {
//   position: fixed;
//   z-index: 99999;
//   top: 90px;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background-color: $white;
// }

// .headFixed {
//   position: fixed;
//   z-index: 99999;
//   width: 100vw;
// }

// .headerNavbar {
//   @include media-breakpoint-up(lg) {
//     padding: 0;
//   }
// }

// .headerNavbarNav {
//   @include media-breakpoint-up(lg) {
//     margin-left: auto;
//   }

//   @include media-breakpoint-down(lg) {
//     width: 690px;
//     margin: 15px auto 0;
//   }

//   @include media-breakpoint-down(md) {
//     width: 510px;
//   }

//   @include media-breakpoint-down(sm) {
//     width: calc(100% - 30px);
//   }
// }

// .headerItem {
//   padding-right: 0.5rem;
//   padding-left: 0.5rem;
//   font-family: $font-heading;
//   font-weight: $font-bold;
// }

// .headerLink {
//   &:not(.headerBtn) {
//     color: $green !important;

//     .textOffset {
//       display: inline-block;
//       border-bottom: 3px solid transparent;
//       line-height: 0.8;
//       transition: border-color 0.3s ease;
//     }

//     &:hover,
//     &:focus {
//       .textOffset {
//         border-color: $red;
//       }
//     }
//   }

//   font-size: 30px;
//   text-transform: uppercase;

//   @include media-breakpoint-down(lg) {
//     &.headerBtn {
//       display: inline-block;
//       padding-right: 15px;
//       padding-left: 15px;
//       margin-top: 15px;
//     }
//   }

//   @include media-breakpoint-up(lg) {
//     padding: 0.5rem 10px !important;
//     line-height: 1;

//     &.headerBtn {
//       margin-left: 17px;

//       &:hover,
//       &:focus {
//         margin-left: 10px;
//       }
//     }
//   }

//   @include media-breakpoint-up(xl) {
//     font-size: 32px;
//   }
// }

// .navItemDropdown {
//   cursor: pointer;
// }

// .dropdownMenu {
//   top: 40px;
//   display: block;
//   padding: 0;
//   border: 0;
//   margin-top: 0;

//   @include media-breakpoint-up(lg) {
//     padding: 10px 0;
//     box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
//   }
// }
