@import 'styles/_scaffold';

.dropdownContainer {
  width: 100%;
  height: 100%;
  padding: 35px $grid-gutter-width;
  background: $red;
  color: $white;
}

.embed {
  @include media-breakpoint-up(lg) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

.eventIntro {
  padding-bottom: 30px;
}

.eventSingle {
  padding-top: 3rem;
}

.eventSingle.infoSingle {
  padding-top: 0;
}

.eventTitle {
  color: $orange;
  font-weight: 700;
}

.eventHost {
  font-weight: 700;
}

.eventLink {
  font-size: 0.8em;
  font-weight: 700;
}

.fieldState {
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.select {
  appearance: menulist;
}
