@import 'styles/_scaffold';

.sectionHeading {
  margin-bottom: 1em;
  color: $orange;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }
}
