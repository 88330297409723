@import 'styles/_scaffold';

.flexFormContent {
  overflow-x: hidden;
}

.formContentCol {
  width: 100%;
  padding: 35px $grid-gutter-width;
  background: url('../../../public/form-bg.png');
  background-color: $green;
  background-size: cover;
  color: $white;

  @include media-breakpoint-up(sm) {
    padding: 40px;
  }

  @include media-breakpoint-up(md) {
    padding: 50px;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    min-height: 500px;
    align-items: center;
    justify-content: center;
    padding: 70px 50px;
  }
}

.formEmbedCol {
  width: 100%;
  height: 100%;
  padding: 35px $grid-gutter-width;
  background: $red;
  color: $white;

  @include media-breakpoint-up(sm) {
    padding: 40px;
  }

  @include media-breakpoint-up(md) {
    padding: 50px;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 50px;
  }
}

.formContentInner {
  max-width: 491px;
  margin-right: auto;
  margin-left: auto;
  color: $black;
}

.formOuter {
  display: flex;
  width: 50%;
  columns: 2;
}

.sectionHeading {
  margin-bottom: 20px;
  font-family: $font-sans;
  font-size: 30px;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;

  strong {
    font-weight: 700;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 30px;
    font-size: 50px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 60px;
  }
}

.sectionContent {
  font-size: 18px;
  line-height: 1.5;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 24px;
  }
}

.sectionButton {
  margin-top: 30px;
  text-align: center;
}

.formHeading {
  display: block;
  max-width: 411px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  color: $white;
  font-size: 36px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;

  @include media-breakpoint-up(lg) {
    font-size: 46px;
    font-weight: bold;
    line-height: 56px;
  }
}
