@import 'styles/_scaffold';

.partnersContainer {
  padding-top: 30px;
  padding-bottom: 40px;

  @include media-breakpoint-only(md) {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    padding-bottom: 130px;
  }
}

.headingText {
  font-family: $font-heading;
  font-size: 20px;
  font-weight: $font-bold;
  letter-spacing: 3px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-only(md) {
    font-size: 24px;
    line-height: 28px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 30px;
    line-height: 36px;
  }
}

.contentRow {
  padding-top: 20px;
  padding-bottom: 20px;

  @include media-breakpoint-only(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  a {
    display: block;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.bodyText {
  font-size: 18px;

  @include media-breakpoint-down(sm) {
    padding-left: 15px;
  }

  @include media-breakpoint-only(md) {
    font-size: 19px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}
