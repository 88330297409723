@import 'styles/_scaffold';

.outer {
  padding: 35px 0;
  background-color: $offwhite;

  @include media-breakpoint-up(md) {
    padding: 60px 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 90px 0;
  }
}

.sectionHeading {
  margin-bottom: 1em;
  color: $orange;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
    font-size: 40px;
  }
}

.news-post-link {
  display: block;
  max-width: 360px;
  border-bottom: 4px solid $yellow;
  margin-right: auto;
  margin-bottom: 45px;
  margin-left: auto;
  background-color: $white;
  text-decoration: none;
  transition: box-shadow 0.3s ease, transform 0.2s ease;

  &:hover,
  &:focus {
    box-shadow: 4px 0 0 $yellow;
    transform: scale(1.02);

    .arrow {
      transform: translateX(12%);
    }
  }

  @include media-breakpoint-up(xl) {
    max-width: 96%;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 92%;
  }

  time {
    display: block;
    margin-bottom: 20px;
    color: $green;
    font-family: $font-sans;
    font-size: 14px;
    font-weight: $font-bold;
    letter-spacing: 2px;
    line-height: 1.3;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      margin-bottom: 35px;
      font-size: 16px;
    }
  }
}

.news-post-text {
  display: flex;
  flex-direction: column;
  padding: 14px 20px;
  color: $orange;
  font-weight: $font-bold;
  line-height: 1.3;
  text-decoration: none;

  @include media-breakpoint-up(lg) {
    padding: 15px 30px;
  }

  @include media-breakpoint-up(xl) {
    padding: 15px 35px;
  }

  h4 {
    //title text
    flex: 2;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 1.33;

    @include media-breakpoint-up(lg) {
      font-size: 30px;
    }
  }

  .arrow {
    position: relative;
    max-width: 34px;
    flex: 1;
    align-self: flex-end;
    transition: transform 0.4s ease;

    @include media-breakpoint-up(lg) {
      left: 10px;
      max-width: 40px;
    }

    @include media-breakpoint-up(xl) {
      left: 15px;
      max-width: 50px;
    }
  }
}
