@import 'styles/_scaffold';

.flex-partnerslookup {
  padding-top: 0 !important;
}

.partnersHeader {
}

.sectionHeading {
  color: $secondary;
  font-weight: 900;

  @include media-breakpoint-up(lg) {
    font-size: 36px;
  }
}

.stateGlyph {
  max-width: 60px;
  margin-right: 20px;
}

.sectionIntro {
  @include media-breakpoint-up(lg) {
    margin-top: 30px;
  }

  p {
    &:last-child {
      padding-bottom: 30px;
    }
  }
}

.result {
  margin-top: 20px;

  @include media-breakpoint-up(lg) {
    margin-top: 30px;
  }
}

.resultInner {
  height: 100%;
  padding: 30px;
  border: 1px solid $secondary;
  background-color: transparentize($color: $secondary, $amount: 0.96);

  span {
    display: block;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.resultTitle {
  font-weight: 700;
  text-transform: none;
}
