@import 'styles/_scaffold';

.footer {
  padding: 30px;
  background: $green;
  font-family: $font-sans;
  font-size: 18.66px;
  font-weight: $font-bold;

  .socialButton {
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none !important;
  }

  a,
  .socialButton {
    display: inline-block;
    border-bottom: 2px solid transparent;
    margin-left: 25px;
    color: $white;
    transition: all 0.3s ease;

    svg {
      width: 1.5em;
      height: 1.5em;
    }

    &:hover,
    &:focus {
      border-color: $white;
    }
  }

  a:hover {
    text-decoration: none;
  }

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.nav {
  li {
    margin-right: 0;

    &::after {
      padding-left: 0.5rem;
      color: $gray-300;
      content: '|';
    }

    &:last-child {
      &::after {
        content: '';
      }
    }
  }
}

.secondaryNav {
  a {
    margin-right: 10px;
    margin-left: 0;
  }
}
