@import 'styles/_scaffold';

.mapColumn {
  @include media-breakpoint-only(lg) {
    padding-right: 40px;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 60px;
  }
}

.formColumn {
  @include media-breakpoint-down(md) {
    height: 100% !important;
  }
}
