@import 'styles/_scaffold';

$bottom-padding-xs: 100px;
$bottom-padding-sm: 110px;
$bottom-padding-md: 120px;
$bottom-padding-lg: 180px;
$bottom-padding-xl: 200px;

.outer {
  position: relative;
  overflow: hidden;
  padding: 32px 0 $bottom-padding-xs;
  background-color: $white;
  background-image: url(/bg-scribble.png);
  background-size: cover;

  @include media-breakpoint-up(sm) {
    padding: 50px 0 $bottom-padding-sm;
  }

  @include media-breakpoint-up(md) {
    padding: 80px 0 $bottom-padding-md;
  }

  @include media-breakpoint-up(lg) {
    padding: 100px 0 $bottom-padding-lg;
  }

  @include media-breakpoint-up(xl) {
    padding: 130px 0 $bottom-padding-xl;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: calc(50% - 50vw);
    width: 100%;
    height: 0;
    border-top: $bottom-padding-xs * 0.25 solid transparent;
    border-right: 50vw solid $white;
    border-bottom: $bottom-padding-xs * 0.12 solid $white;
    border-left: 50vw solid $white;
    content: ' ';

    @include media-breakpoint-up(sm) {
      border-top-width: $bottom-padding-sm * 0.3;
      border-bottom-width: $bottom-padding-sm * 0.12;
    }

    @include media-breakpoint-up(md) {
      border-top-width: $bottom-padding-md * 0.3;
      border-bottom-width: $bottom-padding-md * 0.12;
    }

    @include media-breakpoint-up(lg) {
      border-top-width: $bottom-padding-lg * 0.3;
      border-bottom-width: $bottom-padding-lg * 0.12;
    }

    @include media-breakpoint-up(xl) {
      border-top-width: $bottom-padding-xl * 0.3;
      border-bottom-width: $bottom-padding-xl * 0.12;
    }
  }
}

.calloutContent {
  position: relative;
  padding: 30px;

  @include media-breakpoint-up(sm) {
    padding: 30px 50px;
  }

  @include media-breakpoint-up(md) {
    padding: 40px 60px;
  }

  @include media-breakpoint-up(lg) {
    padding: 50px 80px;
  }

  @include media-breakpoint-up(xl) {
    padding: 60px 100px;
  }

  &::before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    box-shadow: 10px 10px 0 $green;
    content: ' ';

    @include media-breakpoint-up(lg) {
      box-shadow: 18px 18px 0 $green;
    }
  }

  &::after {
    position: absolute;
    z-index: 1;
    bottom: -$bottom-padding-xs;
    left: 50%;
    width: $bottom-padding-xs;
    height: $bottom-padding-xs;
    margin-left: -1 * divide($bottom-padding-xs, 2);
    background-image: url(/callout-hand.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: ' ';

    @include media-breakpoint-up(sm) {
      bottom: -$bottom-padding-sm;
      width: $bottom-padding-sm;
      height: $bottom-padding-sm;
      margin-left: -1 * divide($bottom-padding-sm, 2);
    }

    @include media-breakpoint-up(md) {
      bottom: -$bottom-padding-md;
      width: $bottom-padding-md;
      height: $bottom-padding-md;
      margin-left: -1 * divide($bottom-padding-md, 2);
    }

    @include media-breakpoint-up(lg) {
      bottom: -$bottom-padding-lg;
      width: $bottom-padding-lg;
      height: $bottom-padding-lg;
      margin-left: -1 * divide($bottom-padding-lg, 2);
    }

    @include media-breakpoint-up(xl) {
      bottom: -$bottom-padding-xl;
      width: $bottom-padding-xl;
      height: $bottom-padding-xl;
      margin-left: -1 * divide($bottom-padding-xl, 2);
    }
  }

  * {
    position: relative;
    z-index: 1;
  }

  p {
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }

    @include media-breakpoint-up(md) {
      font-size: 30px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
  }
}

.sectionHeading {
  margin-bottom: 1em;
  color: $orange;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }
}
